<template>
  <div>
    <el-row style="margin-top: 20px">
      <el-form :inline="true" :model="queryForm">
        <el-form-item label="考号" class="formItemBoxStyle">
          <el-input
            v-model="queryForm.examineeCode"
            placeholder="请输入"
            @change="inquireBtn"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button v-throttle type="primary" @click="inquireBtn"
            >查询</el-button
          >
          <el-button v-throttle @click="refreshBtn">重置</el-button>
        </el-form-item>
        <el-form-item style="float: right">
          <el-button v-throttle type="primary" @click="addBtn"
            >批量开启AI阅卷</el-button
          >
          <el-button v-throttle type="primary" @click="addAllBtn"
            >一键开启AI阅卷</el-button
          >
        </el-form-item>
      </el-form>
    </el-row>
    <el-row>
      <el-table
        header-align="center"
        border
        :header-cell-style="{ background: '#DFE6EC' }"
        :data="tableData"
        stripe
        style="width: 100%"
        @selection-change="handleSelectionChange"
        v-loading="loadingTable"
      >
        <el-table-column
          align="center"
          type="selection"
          width="55"
        ></el-table-column>
        <el-table-column
          prop="studentName"
          align="center"
          label="考生姓名"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="examineeCode"
          align="center"
          label="考号"
        ></el-table-column>
        <el-table-column
          prop="sysOrgAreaName"
          align="center"
          label="所属区县"
        ></el-table-column>
        <el-table-column
          prop="sysOrgSchoolName"
          align="center"
          label="所属学校"
        ></el-table-column>

        <el-table-column
          prop="sysOrgExamPlaceName"
          align="center"
          label="考场"
        ></el-table-column>
        <el-table-column
          prop="examTimeSession"
          align="center"
          label="场次"
          width="60"
        ></el-table-column>
        <el-table-column
          prop="sysOrgOperationTestName"
          align="center"
          label="考题"
        ></el-table-column>
        <el-table-column align="center" label="AI评分状态" width="120">
          <template slot-scope="scope">
            <span v-if="scope.row.aiMarkStatus == 0">未开始评分</span>
            <span v-else-if="scope.row.aiMarkStatus == 1">正在评分</span>
            <span v-else-if="scope.row.aiMarkStatus == 4">评分结束</span>
            <span v-else-if="scope.row.aiMarkStatus == 7">等待评分</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="aiScore"
          align="center"
          label="AI评分分数"
          width="100"
        >
        </el-table-column>
        <el-table-column
          prop="resultConfidence"
          align="center"
          label="是否置信"
          width="80"
        >
          <template slot-scope="resultConfidence">
            <span
              :style="
                resultConfidence.row.resultConfidence
                  ? 'color:#67C23A'
                  : 'color:#E6A33F'
              "
              >{{ resultConfidence.row.resultConfidence ? '是' : '否' }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="resultConfidenceNum"
          align="center"
          label="置信度"
          width="80"
        ></el-table-column>
        <el-table-column align="center" label="操作" width="100">
          <template slot-scope="scope">
            <el-button
              v-throttle
              type="primary"
              @click="videoViewBtn(scope.row.examineeId)"
              size="mini"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="tableDataPagination.pageIndex"
        :page-sizes="[10, 20, 50, 100, 150, 200]"
        :page-size="tableDataPagination.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableDataPagination.total"
      ></el-pagination>
    </el-row>
  </div>
</template>
<script>
import {
  getAIExamineeMarkList,
  addAllExaminee,
  addExaminee,
} from '@/api/marking/ai.js'
export default {
  name: 'AIConcentratedScore',
  data() {
    return {
      examId: null,
      queryForm: {
        examineeCode: '',
      },
      loadingTable: false,
      tableData: [],
      // 分页
      tableDataPagination: {
        // 当前页
        pageIndex: 1,
        // 页大小
        pageSize: 20,
        // 总数量
        total: 0,
      },
      selectionList: [],
    }
  },
  mounted() {
    this.examId = this.$route.query.examId
    this.getAIExamineeMarkListFunc()
  },
  methods: {
    getAIExamineeMarkListFunc() {
      let data = {
        examId: this.examId,
        examineeCode: this.queryForm.examineeCode,
      }
      for (let key in data) {
        if (data[key] == '' || data[key] == undefined) {
          delete data[key]
        }
      }
      this.loadingTable = true
      getAIExamineeMarkList(
        this.tableDataPagination.pageIndex,
        this.tableDataPagination.pageSize,
        data
      )
        .then((res) => {
          this.loadingTable = false
          this.selectionList = []
          if (res.success) {
            this.tableData = res.data
            this.tableDataPagination.total = res.total
          }
        })
        .catch((err) => {})
    },
    inquireBtn() {
      this.getAIExamineeMarkListFunc()
    },
    refreshBtn() {
      this.queryForm.examineeCode = ''
      this.tableDataPagination.pageIndex = 1
      this.getAIExamineeMarkListFunc()
    },
    // console.log(`每页 ${val} 条`);
    handleSizeChange(val) {
      this.tableDataPagination.pageSize = val
      this.tableDataPagination.pageIndex = 1
      this.getAIExamineeMarkListFunc()
    },
    // console.log(`当前页: ${val}`);
    handleCurrentChange(val) {
      this.tableDataPagination.pageIndex = val
      this.getAIExamineeMarkListFunc()
    },
    handleSelectionChange(val) {
      this.selectionList = val
    },
    addBtn() {
      console.log('this.selectionList', this.selectionList)
      if (this.selectionList.length) {
        let idList = this.selectionList.map((e) => e.studentId)
        addExaminee(this.examId, idList)
          .then((res) => {
            if (res.success) {
              this.$message.success('添加成功')
              this.refreshBtn()
            } else {
              this.$message.info(res.msg)
            }
          })
          .catch((err) => {})
      } else {
        return this.$message.info('请勾选学生')
      }
    },
    addAllBtn() {
      addAllExaminee(this.examId)
        .then((res) => {
          if (res.success) {
            this.$message.success('添加成功')
            this.refreshBtn()
          } else {
            this.$message.info(res.msg)
          }
        })
        .catch((err) => {})
    },
    // 查看
    videoViewBtn(val) {
      console.log('val', val)
      this.$router.push({
        path: '/scoreAI/AIConcentratedScoreVideo',
        query: {
          examineeId: val,
        },
      })
    },
  },
}
</script>
<style lang="scss" scoped></style>
